export default [
  {
    title: 'Home',
    route: 'dashboard',
    icon: 'HomeIcon',
    Permission: '',
  },

  {
    title: 'Clients',
    icon: 'UsersIcon',
    Permission: 'client_page_menu',
    route: 'clients',
  },

  {
    title: 'Contacts',
    icon: 'UserIcon',
    Permission: 'contact_page_menu',
    route: 'contact-list',
  },

  {
    title: 'Todo',
    route: 'todo-dashboard',
    icon: 'CheckSquareIcon',
    Permission: 'todo_page_menu',
  },

  {
    title: 'Files',
    route: 'file-list',
    icon: 'FolderIcon',
    Permission: 'files_page_menu',
  },

  {
    title: 'Projects',
    route: 'projects',
    icon: 'GridIcon',
    Permission: 'projects_page_menu',
  },

  // {
  //   title: 'Invoices',
  //   route: 'invoice-list',
  //   icon: 'ClipboardIcon',
  //   Permission: '',
  // },

  // {
  //   title: 'Quotes',
  //   route: 'quote-list',
  //   icon: 'FileIcon',
  //   Permission: '',
  // },

  // {
  //   title: 'Scopes',
  //   icon: 'InboxIcon',
  //   Permission: '',
  //   children: [
  //     {
  //       title: 'Create Scope',
  //       route: 'scope-add',
  //       icon: 'DatabaseIcon',
  //       Permission: '',
  //     },

  //     {
  //       title: 'Scope List',
  //       route: 'scopes-list',
  //       icon: 'HashIcon',
  //       Permission: '',
  //     },

  //     {
  //       title: 'Edit',
  //       route: 'scopes-edit',
  //       icon: 'InboxIcon',
  //       disabled: true,
  //       Permission: '',
  //     },
  //   ],
  // },

  {
    title: 'Leads',
    icon: 'InboxIcon',
    Permission: 'leads_page_menu',
    route: 'leads-dashboard',
  },

  {
    title: 'Support Requests',
    icon: 'BellIcon',
    Permission: '',
    route: 'supports-dashboard',
  },

  {
    title: 'Helpdesk',
    icon: 'HelpCircleIcon',
    Permission: '',
    route: 'helpdesks',
  },

  {
    title: 'Portfolio',
    icon: 'CastIcon',
    Permission: 'portfolio_page_menu',
    route: 'portfolio-list',
  },

  {
    title: 'Users',
    icon: 'UsersIcon',
    Permission: 'user_page_menu',
    route: 'user-list',
  },

  {
    title: 'Clockify',
    icon: 'ClockIcon',
    Permission: 'clockify_page_menu',
    children: [
      {
        title: 'Clients',
        route: 'clockify-client',
        icon: 'UserPlusIcon',
        Permission: 'clockify_clients_tab',
      },
      {
        title: 'Projects',
        route: 'clockify-project',
        icon: 'ListIcon',
        Permission: 'clockify_projects_tab',
      },
      {
        title: 'User Report',
        route: 'clockify-user-reports',
        icon: 'ListIcon',
        Permission: 'clockify_user_report',
      },

      {
        title: 'Client Report',
        route: 'clockify-client-reports',
        icon: 'ListIcon',
        Permission: 'clockify_client_report',
      },
    ],
  },

  {
    title: 'Settings',
    route: 'settings',
    icon: 'SettingsIcon',
    Permission: 'settings_page_menu',
  },

  {
    title: 'Sign Out',
    route: 'signout',
    icon: 'LogOutIcon',
    Permission: '',
  },
];
